import { Vue, Component } from "vue-property-decorator";

@Component({
  inheritAttrs: false,
})
export default class WalletTransferFundsMenu extends Vue {
  private get localListeners() {
    const listeners = { ...this.$listeners };

    delete listeners.success;
    delete listeners.close;

    return listeners;
  }

  private onSucces<T>(value: T) {
    this.$emit("success", value);
  }
}
